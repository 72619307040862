// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pressable {
  --tw-translate-x: 0px;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.3);
  --tw-shadow-colored: 4px 4px 2px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.pressable:hover {
  --tw-translate-x: 0px;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-shadow: 6px 6px 2px 2px rgba(0, 0, 0, 0.3);
  --tw-shadow-colored: 6px 6px 2px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.pressable:active {
  --tw-translate-x: 0.25rem;
  --tw-translate-y: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@keyframes pressable-ping {
  0% {
    opacity: 0.3;
  }
  75%,
  100% {
    transform: scale(1.9);
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACE;EAAA,qBAAuM;EAAvM,qBAAuM;EAAvM,+LAAuM;EAAvM,+CAAuM;EAAvM,2DAAuM;EAAvM,uGAAuM;EAAvM,gKAAuM;EAAvM,wJAAuM;EAAvM,iLAAuM;EAAvM,wDAAuM;EAAvM;AAAuM;;AAAvM;EAAA,qBAAuM;EAAvM,qBAAuM;EAAvM,+LAAuM;EAAvM,+CAAuM;EAAvM,2DAAuM;EAAvM;AAAuM;;AAAvM;EAAA,yBAAuM;EAAvM,yBAAuM;EAAvM,+LAAuM;EAAvM,sBAAuM;EAAvM,8BAAuM;EAAvM;AAAuM;;AAGzM;EACE;IACE,YAAY;EACd;EACA;;IAEE,qBAAqB;IACrB,UAAU;EACZ;AACF","sourcesContent":[".pressable {\n  @apply shadow-button-sm translate-x-0 translate-y-0 transition duration-300 hover:shadow-button-md hover:translate-x-0 hover:translate-y-0 active:translate-x-1 active:translate-y-1 active:shadow-none;\n}\n\n@keyframes pressable-ping {\n  0% {\n    opacity: 0.3;\n  }\n  75%,\n  100% {\n    transform: scale(1.9);\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
